import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <div className="wrapper wrapper--desktop-width">
      <div className="margin-wrapper">
        <div className="height-box">
          <h1>
            404 Woops! Det ser ikke ut til at denne siden finnes{" "}
            <span role="img" aria-label="emoji">
              🤔
            </span>{" "}
            ...
          </h1>
          <Link to="/">Tilbake til forsiden</Link>
        </div>
      </div>
    </div>
  </Layout>
)

export default NotFoundPage
